import { Alert } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { AuthLayout } from "./layout";

type AuthErrorMessageProps = {
  type?: "error" | "session-error";
};
export const AuthErrorMessage = ({ type = "error" }: AuthErrorMessageProps) => {
  return (
    <AuthLayout>
      <Alert status="error">
        {type === "error" ? (
          <Trans>
            Oops, something unexpected happened. Please try refreshing the page.
          </Trans>
        ) : (
          <Trans>
            For security reasons, your session has expired. Please refresh the
            page and log in again.
          </Trans>
        )}
      </Alert>
    </AuthLayout>
  );
};
